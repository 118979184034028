import { ConnectKitButton } from 'connectkit';

function App() {
  return (
    <div
      style={{
        float: 'right'
      }}
    >
      <ConnectKitButton />
    </div>
  );
}

export default App;
